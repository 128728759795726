import React, { useState, useEffect } from 'react'
import Fab from '@material-ui/core/Fab';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import wa from 'assets/img/icons/whatsapp.svg';
import line from 'assets/img/icons/line.svg';
import Avatar from '@material-ui/core/Avatar';
import admin from 'assets/img/theme/gadis_final.jpg'
import WAChat from 'assets/img/theme/wa_chat.png'
import officialacc from "assets/img/theme/officialacc.png"
import LineChat from 'assets/img/theme/line_chat.png'


let adminUrl = null;
let isTW = false;
export default function FabCustom() {
    const [toggle, setToggle] = useState(false);
    const [hide, setHide] = useState(true)
    const [time, setTime] = useState('Pagi');
    const style = {
        container: {
            position: 'fixed',
            bottom: '16px',
            right: '16px',
            zIndex: '2000'
        },
        fab: {
            display: 'block',
            marginLeft: 'auto'
        },
        chat: {
            marginBottom: '24px',
            width: '340px'
        },
        btn: {
            color: 'white',
            background: '#25D366',
            // background: isTW ? "#654EE6" : '#25D366',
            width: '100%',
            borderRadius: '20px'
        },
        chatHead: {
            background: isTW ? `url(${LineChat})` : '#075E54',
            color: isTW ? '#000' : '#fff',
            padding: '16px',
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: 'dotted',
            borderWidth: '1.5px',
            borderColor: '#000'
        },
        chatBackground: {
            margin: '-16px -16px 16px -16px',
            width: 'calc(100% + 32px)',
            height: isTW ? '320px' : '300px',
            background: isTW ? `url(${LineChat})` : `url(${WAChat})`,
            padding: '16px'
        },
        officialAcc: {
            backgroundImage: `url(${officialacc})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            display: 'inline-block',
            width: '16px',
            height: '16px',
            marginBottom: '-1.5px'
        }
    }
    const getIsTW = async () => {
        let promiseConfig = await require("../../../config/config.js").then(function (e) {
            console.log(e)
            return e
        });
        adminUrl = promiseConfig.adminUrl
        if ((adminUrl === "https://apiprod-tw.i-dealhomeonline.com/admin") || (adminUrl === "https://apidev-tw.i-dealhomeonline.com/admin")) {
            isTW = true
        }
        setToggle(false)
        setHide(false)
    }

    useEffect(() => {
        getIsTW();
        const today = new Date();
        const hour = today.getHours();
        // const minute = today.getMinutes();
        // 02:01-12:00 Selamat Pagi
        // 12:01-15:00 Selamat Siang
        // 15:01-18:00 Selamat Sore
        // 18:01-02:00 Selamat Malam
        if (hour >= 2 && hour <= 12) {
            // if (hour === 2 && minute === 0) {
            // } else {
            // }
            setTime('Pagi');
        }
        if (hour >= 12 && hour <= 15) {
            // if (hour === 12  && minute === 0) {
            // } else {
            // }
            setTime('Siang');
        }
        if (hour >= 15 && hour <= 18) {
            // if (hour === 15  && minute === 0) {
            // } else {
            // }
            setTime('Sore');
        }
        if (hour >= 18 || hour < 2) {
            // if (hour === 18 && minute === 0) {
            // } else {
            //     if (hour === 2 && minute !== 0) {
            //     } else {
            //     }
            // }
            setTime('Malam');
        }

    }, [time])

    return (
        <div style={style.container}>
            {toggle && (
                <Card style={style.chat} elevation={8}>
                    <div style={style.chatHead}>
                        <div style={{ display: 'flex' }}>
                            {isTW ? "" : <Avatar src={admin} style={{ marginRight: '8px' }} />}
                            <div>

                                <div className="m-0">{isTW ? <div style={{ display: 'inline', ...style.officialAcc }}></div> : ""}<b>  iDeal Home Online</b></div>
                                {isTW ? "" : <div className="m-0">Available</div>}
                            </div>
                        </div>
                        <div style={{ cursor: 'pointer' }} onClick={() => setToggle(!toggle)}><b>x</b></div>
                    </div>
                    <CardContent>
                        <div style={style.chatBackground}>
                            <div style={{ display: 'flex' }}>
                                {isTW ? <Avatar src={admin} style={{ marginRight: '8px' }} /> : ""}
                                <Card elevation={4} style={{ padding: '10px', display: 'inline-block', borderRadius: '20px' }}>
                                    <div>Selamat {time}, Bapak / Ibu &#128522;</div>
                                    <div>Ada yang bisa kami bantu?</div>
                                </Card>
                            </div>
                        </div>
                        <a href={isTW ? "https://line.me/R/ti/p/@idealhomeonline" : "https://wa.me/85229935000"} target="blank">
                            <Button variant="contained" style={style.btn}>Mulai Obrolan</Button>
                        </a>
                    </CardContent>
                </Card>
            )}
            {hide ? "" :
                <Fab style={style.fab} onClick={() => setToggle(!toggle)}>
                    <img width="100%" style={{ display: 'block' }} src={isTW ? line : wa} alt="fab"></img>
                </Fab>
            }
        </div>
    )
}
