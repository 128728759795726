import { TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";

const initForm = {
    name: '',
    phonenumber: '',
    subject: 'Permohonan tutup akun'
}

export default function FormDeletion() {
    const [form, setForm] = useState(initForm)
    const inputStyle = {
        borderRadius: 6,
        backgroundColor: "#ffffff",
        fontWeight: 10,
        maxWidth:'100%'
    }

    const labelStyles = {
        position: 'absolute',
        zIndex:1000,
        marginLeft:14
        // pointerEvents: 'none'
    }

    const onChangeForm = (e) => {
        setForm(prev => ({
            ...prev,
            ...e
        }))
    }

    return (
        <div className="pd-20" style={{ color: 'black', backgroundColor: '#F3F3F3', height: '100vh', width: '100%'}}>
            <div style={{ marginBottom: 10,  }}>
                <Typography style={{ ...labelStyles }}>Nama Lengkap</Typography>
                <TextField className="custom-textfield" variant="outlined" fullWidth value={form.name} onChange={(e) => onChangeForm({ name: e.target.value })} placeholder="Masukkan Nama Lengkap" style={{ ...inputStyle }}></TextField>
            </div>
            <div style={{ marginBottom: 10 }}>
                <Typography style={{ ...labelStyles }}>Nomor HP</Typography>
                <TextField className="custom-textfield" variant="outlined" fullWidth value={form.phonenumber} onChange={(e) => onChangeForm({ phonenumber: e.target.value })} placeholder="Masukkan Nomor Handphone" style={{ ...inputStyle }}></TextField>
            </div>
            <div style={{ marginBottom: 10 }}>
                <Typography style={{ ...labelStyles, color: ' #A6A6A6' }}>Subjek</Typography>
                <TextField className="custom-textfield" variant="outlined" fullWidth disabled={true} value={form.subject} onChange={(e) => onChangeForm({ subject: e.target.value })} placeholder="Permohonan tutup akun" style={{ ...inputStyle, backgroundColor:'#F3F3F3', borderStyle:'line', borderColor:'#E8E8E8' }}></TextField>
            </div>
        </div>
    )
}