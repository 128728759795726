import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';

import Device from 'assets/img/theme/device-2.png';

const listItem = [
    {
        id: 1,
        name: "Proses Cepat",
        desc: "Pemesanan diproses dalam 1 x 24 jam",
        url: require('assets/img/theme/proses_cepat.png')
    },
    {
        id: 2,
        name: "Real Time Tracking",
        desc: "Status Pemesanan Jelas & Aktual",
        url: require('assets/img/theme/realtime_tracking.png')
    },
    {
        id: 3,
        name: "Jaminan Uang Kembali",
        desc: "Pengembalian uang secara penuh jika barang tidak terkirim",
        url: require('assets/img/theme/jaminan_uang_kembali.png')
    },
    {
        id: 4,
        name: "Pembayaran Aman",
        desc: "Cicilan Tanpa Bunga dan bayar setelah Barang diterima",
        url: require('assets/img/theme/pembayaran_aman.png')
    },
    {
        id: 5,
        name: "Gratis Ongkos Kirim",
        desc: "Beli di Hongkong, Kirim ke Indonesia",
        url: require('assets/img/theme/gratis_ongkos_kirim.png')
    },
]

class Ideal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: listItem[0].id,
            name: listItem[0].name,
            desc: listItem[0].desc,
            fade: true,
        }
    }
    // event 
    onMouseEnter = value => {
        this.setState({
            fade: false,
        }, ()=> {
            setTimeout(() => {
                this.setState({
                    id: value.id,
                    name: value.name,
                    desc: value.desc,
                    fade: true,
            })
           }, 200);
        })
    }
    render() {
        // style
        const styles = {
            section: {
                paddingTop: "calc(10vh + 40px)",
                paddingBottom: "10vh",
                minHeight: '500px'
            },
            device: {
                background: `url(${Device}) no-repeat center`,
                backgroundSize: 'contain',
                minHeight: 400,
                minWidth: 300,
            }
        }
        //view
        return (
            <section id="service" className="px-3 background" style={styles.section}>
                <Grid
                    container
                    justify="center"
                    alignItems="flex-start"
                    // style={{paddingTop: '24px'}}
                    spacing={5}>
                    <Grid item md={10} container justify="center">
                        {/* left side */}
                        <Grid item sm={5} md={4}>
                            <div 
                                style={styles.device}
                            ></div>
                        </Grid>
                        {/* right side */}
                        <Grid item sm={7} md={8} style={{minHeight: 500}}>
                            <Grid 
                            container
                            spacing={2}
                            className="mt-5"
                            justify="flex-start"
                            alignItems="center">
                                {listItem.map((value, key) => {
                                    return (
                                    <Grid item xs={12} sm={'auto'} md={'auto'} className="cursor-pointer mb-4" 
                                        key={key}
                                        onClick={() => this.onMouseEnter(value)}
                                        onMouseEnter={() => {this.onMouseEnter(value)}}>
                                            <Grid container alignItems="center" style={{flexWrap: 'nowrap'}}>
                                                <Card xs={2} className="card" elevation={this.state.id === value.id ? 8: 0}>
                                                    <img
                                                        width="100%"
                                                        alt={value.name}
                                                        src={value.url}/>
                                                </Card>
                                                <Grid xs={10} className="ml-3 d-sm-none d-md-none d-lg-none">
                                                    <Typography variant="h5" className="primary-text" gutterBottom><b>{value.name}</b></Typography>
                                                    <Typography variant="subtitle1" className="primary-text" gutterBottom>{value.desc}</Typography>
                                                </Grid>

                                            </Grid>
                                    </Grid>
                                    )
                                })}
                            </Grid>
                            <Grid container item direction="column" className="mt-4 d-none d-sm-block" style={{minHeight: "5vh"}}>
                                <Fade in={this.state.fade}>
                                    <div>
                                        <Typography variant="h5" className="primary-text" gutterBottom><b>{this.state.name}</b></Typography>
                                        <Typography variant="subtitle1" className="primary-text" gutterBottom>{this.state.desc}</Typography>
                                    </div>
                                </Fade>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </section>
        );
    }
}

export default Ideal;