import { Box, Card, Dialog, Link, Typography } from "@material-ui/core";
import React, { Component, useEffect, useState } from "react";
import FormDeletion from './formDeletion';
import HotLineHk from '../../assets/img/theme/CS-HK.png';
import HotLineTw from '../../assets/img/theme/CS-TW.png';

function AccountDeletion(props) {
    const [isTW, setIsTW] = useState(false)
    const [isWebview, setIsWebview] = useState(false)
    let adminUrl = null;

    useEffect(() => {
        checkTW()
        checkWebView()
        console.log('ini cek istw',isTW)
        console.log('ini cek iswebview',isWebview)
    }, [])

    const styles = {
        hotLineHk: {
            display: 'block',
            maxWidth: '190px',
            // marginTop: '8px',
            marginBottom: '16px',
            background: `url(${HotLineHk}) no-repeat top`,
            backgroundSize: 'contain',
            height: 'auto',
            height: 100,
        },
        hotLineTw: {
            display: 'block',
            maxWidth: '218px',
            // marginTop: '8px',
            marginBottom: '16px',
            background: `url(${HotLineTw}) no-repeat top`,
            backgroundSize: 'contain',
            height: 100,
        },
    }

    const checkTW = async () => {
        let promiseConfig = await require("../../config/config.js").then(function (e) {
            console.log(e)
            return e
        });
        adminUrl = promiseConfig.adminUrl
        if ((adminUrl === "https://apiprod-tw.i-dealhomeonline.com/admin") || (adminUrl === "https://apidev-tw.i-dealhomeonline.com/admin")) {
            setIsTW(true)
        }
    }

    const checkWebView = () =>{
        let currentUrl= window.location.href;
        if(currentUrl.endsWith('wv-account-deletion')){
            console.log('ini cek webview', true)
            setIsWebview(true)
        }
    }

    // const [dialogOpen, setDialogOpen] = useState(false)
    return (
        <div className="pd-20 bg-white" style={{ color: 'black' }}>
            <p>
                <strong><span style={{ fontSize: "18px", fontWeight: 'Bold' }}>Bagaimana cara menutup akun iDeal Saya ?</span></strong>
            </p>
            <p>
                <span style={{ fontSize: "14px", fontFamily: "Arial, Helvetica, sans-serif" }}>Kamu dapat melakukan penutupan akun iDeal dengan ketentuan sebagai berikut :</span>
            </p>
            <ol style={{ paddingLeft: '20px', listStyle: 'none', counterReset: 'item' }}>
                <li style={{ counterIncrement: 'item', marginBottom: '10px', position: 'relative' }}>
                    <span style={{ position: 'absolute', left: 0, fontWeight: 'bold', fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif' }}>
                        <span style={{ display: 'inline-block', width: '20px' }}>1.</span>
                    </span>
                    <span style={{ display: 'inline-block', paddingLeft: '25px', fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif' }}>
                        Pastikan Saldoku yang tersisa/tidak terpakai <span style={{ fontWeight: 'bold' }}>sudah habis</span> atau tersisa 0.
                    </span>
                </li>
                <li style={{ counterIncrement: 'item', marginBottom: '10px', position: 'relative' }}>
                    <span style={{ position: 'absolute', left: 0, fontWeight: 'bold', fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif' }}>
                        <span style={{ display: 'inline-block', width: '20px' }}>2.</span>
                    </span>
                    <span style={{ display: 'inline-block', paddingLeft: '25px', fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif' }}>
                        Pasca penutupan akun iDeal, maka kamu <span style={{ fontWeight: 'bold' }}>tidak dapat melakukan transaksi</span> atau menggunakan fitur yang ada di dalam aplikasi iDeal.
                    </span>
                </li>
                <li style={{ counterIncrement: 'item', marginBottom: '10px', position: 'relative' }}>
                    <span style={{ position: 'absolute', left: 0, fontWeight: 'bold', fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif' }}>
                        <span style={{ display: 'inline-block', width: '20px' }}>3.</span>
                    </span>
                    <span style={{ display: 'inline-block', paddingLeft: '25px', fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif' }}>
                        Setelah akun kamu berhasil ditutup, iDeal akan tetap menyimpan seluruh data transaksi untuk keperluan audit.
                    </span>
                </li>
                <li style={{ counterIncrement: 'item', marginBottom: '10px', position: 'relative' }}>
                    <span style={{ position: 'absolute', left: 0, fontWeight: 'bold', fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif' }}>
                        <span style={{ display: 'inline-block', width: '20px' }}>4.</span>
                    </span>
                    <span style={{ display: 'inline-block', paddingLeft: '25px', fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif' }}>
                        Dengan mengajukan penutupan akun iDeal, kamu telah setuju dengan Privacy Policy.
                    </span>
                </li>
                <li style={{ counterIncrement: 'item', marginBottom: '10px', position: 'relative' }}>
                    <span style={{ position: 'absolute', left: 0, fontWeight: 'bold', fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif' }}>
                        <span style={{ display: 'inline-block', width: '20px' }}>5.</span>
                    </span>
                    <span style={{ display: 'inline-block', paddingLeft: '25px', fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif' }}>
                        Customer Care iDeal akan melayani proses penutupan akun iDeal kamu dan akan diminta untuk memberikan kelengkapan dokumen yang diperlukan untuk penutupan akun iDeal.
                    </span>
                </li>
            </ol>
            <p>
                <span style={{ fontSize: "14px", fontFamily: "Arial, Helvetica, sans-serif" }}>Akun kamu akan ditutup oleh tim iDeal dengan estimasi waktu 3 hari kerja sejak permohonan tutup akun kamu disetujui.</span>
            </p>

            {!isWebview && isTW &&
                <div>
                    <Typography gutterBottom className="main-text mt-3 pb-2"><b>Customer Care Hotline:</b></Typography>
                    <Link href="tel:+886223113000" className=" d-block" title="Hotline iDeal" style={{ borderRadius: '20px' }}>
                        <div style={styles.hotLineTw} alt="HotLine iDeal" width="100%"></div>
                    </Link>
                </div>
            }
            {!isWebview && !isTW && 
                <div>
                    <Typography gutterBottom className="main-text mt-3 pb-2"><b>Customer Care Hotline:</b></Typography>
                    <Link href="tel:+85229935000" className=" d-block" title="Hotline iDeal" style={{ borderRadius: '20px' }}>
                        <div style={styles.hotLineHk} alt="HotLine iDeal" width="100%"></div>
                    </Link>
                </div>
            }
            {/* <Dialog open={dialogOpen} maxWidth={'md'} fullWidth onClose={() => setDialogOpen(false)}>
                <Box style={{
                    height: '100vh',
                    boxSizing: 'border-box', overflow:'hidden'
                }}>
                    <FormDeletion />
                </Box>
            </Dialog> */}
        </div>
    )
}
export default AccountDeletion;