import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

// import 'assets/vendor/nucleo/css/nucleo.css';
import 'assets/vendor/font-awesome/css/font-awesome.min.css';
import 'assets/scss/main.scss';
import 'assets/scss/style.scss';

import Index from 'views/Index.js';
import PrivacyPolicy from 'views/home/privacyPolicy.js';
import AccountDeletion from 'views/home/accountDeletion';
import FormDeletion from 'views/home/formDeletion';
// import DownloadApk from 'views/home/DownloadApk';

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path='/' exact render={(props) => <Index {...props} />} />
      <Route path='/home' exact render={(props) => <Index {...props} />} />
      <Route path='/service' exact render={(props) => <Index {...props} />} />
      <Route path='/testimonial' exact render={(props) => <Index {...props} />} />
      <Route path='/location' exact render={(props) => <Index {...props} />} />
      <Route
        path='/privacy-policy'
        exact
        render={(props) => <PrivacyPolicy {...props} />}
      />
      <Route
        path='/account-deletion'
        exact
        render={(props) => <AccountDeletion {...props} />}
      />
      <Route
        path='/form-account-deletion'
        exact
        render={(props) => <FormDeletion {...props} />}
      />
      <Route
        path='/wv-account-deletion'
        exact
        render={(props) => <AccountDeletion {...props} />}
      />
      <Route
        path='/wv-form-account-deletion'
        exact
        render={(props) => <FormDeletion {...props} />}
      />
      {/* <Route
        path='/downloadapk'
        component={DownloadApk}
      /> */}
      <Redirect to='/' />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);
