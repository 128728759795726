import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Card from '@material-ui/core/Card';
import { Avatar } from '@material-ui/core';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// component
import PlayStore from "../../../components/PlayStore/PlayStore.js";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// import { adminUrl } from "../../../config/config.js";

import admin from 'assets/img/theme/gadis_final.jpg'

class Intro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSnackBar: false,
      showDialog: false,
      showSuccessDialog: false,
      name: '',
      phonenumber: '',
    }
  }
  cancel = () => {
    // hide dialog
    // clear state
    this.setState({
      showDialog: false,
      name: '',
      phonenumber: ''
    });
  }
  submit = async () => {
    if (this.state.name && this.state.phonenumber) {
      let local = this;
      let promiseConfig = await require("../../../config/config.js").then(function (e) {
          console.log(e)
          return e
      });
      let adminUrl = promiseConfig.adminUrl
      fetch(`${adminUrl}/pandusaya`, {
        method: 'POST',
        headers: {
        Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          // city: this.state.city || '',
          // region: this.state.region || '',
          // country: this.state.country || '',
          name: this.state.name,
          phonenumber: this.props.userLocation.country==="Taiwan"?`+886${this.state.phonenumber}`:`+852${this.state.phonenumber}`,
        }),
      }).then(function () {
        // local.onCloseSnackBar();
        local.cancel();
        local.toggleSuccessDialog();
      });
    }
  }

  toggleSuccessDialog = () => {
    this.setState((state)=> ({ showSuccessDialog: !state.showSuccessDialog}));
  }

  donwloadAPK = async () => {
    let promiseConfig = await require("../../../config/config.js").then(function (e) {
        console.log(e)
        return e
    });
    let adminUrl = promiseConfig.adminUrl
    fetch(`${adminUrl}/visitplaystore`, {
        method: 'POST',
        headers: {
        Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            city: this.props.userLocation? this.props.userLocation.city: '',
            region: this.props.userLocation? this.props.userLocation.region: '',
            country: this.props.userLocation? this.props.userLocation.country: '',
            status: 'download_apk',
        }),
    }).then(function () {
        window.location.href = 'https://ideal-homeonline-production.s3-ap-southeast-1.amazonaws.com/iDealHomeOnline.apk';
    });
    
};
  // listener
  onClickDialog = () => {
    this.setState(state => ({ showDialog: !state.showDialog }));
  }

  onCloseSnackBar = () => {
    this.setState(state => ({ showSnackBar: !state.showSnackBar }));
  }

  onChangeInput = e => {
    this.setState({
      [e.target.name] : e.target.value,
    });
  }
  // phonenumber
  onChangeInputPhoneNumber = e => {
    this.setState({
      [e.target.name] : e.target.value.replace(/[^0-9]/g, ''),
    });
  }
  // view
  render() {
    // let imgsUrl = [];
    // let content = this.props.content;
    let isUseApk = this.props.isUseApk;
    // if(content) {
    //   content.forEach(value => {
    //     if (value.name === "intro") {
    //       imgsUrl = value.imageslink;
    //     }
    //   })
    // }
    const responsive = {
      superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 1,
          slidesToSlide: 1
      },
      desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1,
          slidesToSlide: 1
      },
      tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1,
          slidesToSlide: 1
      },
      mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
      }
  };
    // styles
    const styles = {
      section: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: "no-repeat",
        paddingTop: '50px',
        backgroundColor: '#fafafa'
      },
      grid: {
        minHeight: "calc(100vh - 60px)",
        padding: window.innerWidth < 600 ? '40px 20px' : '40px',
      },
      white: {
        color: "white",
        whiteSpace: 'nowrap',
      },
      btn: {
        height: "100%",
        width: "100%",
        minHeight: "auto",
        textTransform: "none",
      },
      btnBlack: {
        height: "100%",
        width: "100%",
        padding: 0,
        textTransform: "none",
        backgroundColor: "black"
      },
      avatar: {
        width: '120px',
        height: '120px',
        marginBottom: '16px'
      },
      carousel: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      
      }
    }
    return (
      <section id="home" style={styles.section} className="">
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
          style={styles.grid}
        >
          {/*  */}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Typography variant="h4" className="main-text mb-3" style={{fontWeight: '500', maxWidth: 500}}>
              Belanja Tanpa Ribet Untuk Orang Kesayanganmu
            </Typography>
            <Typography variant="subtitle1" className="main-text mb-5" style={{maxWidth: 500, textAlign: 'justify'}}>
              iDeal Home Online memberikan kemudahan berbelanja untuk orang kesayangan Anda di Indonesia. Belanja beragam produk <b>#PastiORI</b> dan dapatkan <b>GRATIS ONGKIR</b> KE SELURUH INDONESIA.
            </Typography>
            <Grid container justify="center" spacing={1} style={{maxWidth: '500px'}}>
              { !isUseApk && (
                <Grid item xs={6} sm={6} md={6}>
                  <PlayStore userLocation={this.props.userLocation || {}}/>
              </Grid>
              )}
              { isUseApk && (
                <Grid item xs={6} sm={6} md={6}>
                  <Button variant="contained" style={styles.btnBlack} onClick={this.donwloadAPK}>
                    <Typography variant="h5" style={styles.white}>Download APK
                    <i className="fa fa-download ml-2" aria-hidden="true"></i>
                    </Typography>
                  </Button>
                </Grid>
              )}
              <Grid item xs={6} sm={6} md={6}>
                  <Button variant="contained" color="primary" style={{...styles.btn, maxHeight: '63.8%'}} onClick={() => this.onClickDialog(true)}>
                    <Typography variant="h5" style={styles.white}>Pandu Saya</Typography>
                  </Button>
              </Grid>
            </Grid>
          </Grid>
          {/*  */}
          {this.props.bannerData.length > 0 && (
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div style={{margin: '0 -1rem'}}>
                <Carousel
                  responsive={responsive}
                  showDots={false}
                  arrows={false}
                  autoPlay
                  autoPlaySpeed={3000}
                  infinite={true}>
                    {this.props.bannerData.map((value, index) => {
                    return (
                      <div style={styles.carousel} className="p-3" key={index}>
                        <Card elevation={8} style={{borderRadius: 10}}>
                            <LazyLoadImage
                              alt={`homepage ${index}`}
                              effect="blur"
                              src={value.imageUrl} // use normal <img> attributes as props
                              width={"100%"} />
                        </Card>
                      </div>
                    )
                    })}
                  </Carousel>
              </div>
            </Grid>
          )}
          
        </Grid>
        {/* dialog */}
        <Dialog open={this.state.showDialog} fullWidth={true} onClose={this.onClickDialog} aria-labelledby="form-dialog-title">
          <DialogContent>
            <Typography color="primary" variant="h5" style={{margin: '24px 0'}}>Saya ingin bergabung berbelanja di iDeal:</Typography>
            <TextField
              autoFocus
              required
              fullWidth
              margin="dense"
              className="mb-3"
              label="Nama"
              id="name"
              type="text"
              placeholder="Cth: Susi"
              // placeholder="Masukkan Nama"
              name="name"
              value={this.state.name}
              onChange={this.onChangeInput}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
            <TextField
              required
              fullWidth
              margin="dense"
              className="mb-4"
              label="Nomor Handphone"
              id="phonenumber"
              placeholder="Cth: 8888 8888"
              // placeholder="Masukkan Nomor Handphone"
              name="phonenumber"
              value={this.state.phonenumber}
              onChange={this.onChangeInputPhoneNumber}
              InputProps={{
                startAdornment: this.props.userLocation.country==="Taiwan"?<InputAdornment position="start">(+886)</InputAdornment>:<InputAdornment position="start">(+852)</InputAdornment>,
              }}
              variant="outlined"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.submit} color="primary" variant="contained" disabled={!(this.state.phonenumber.length > 7 && this.state.name)}>
              Pandu Saya
            </Button>
          </DialogActions>
        </Dialog>
        
        <Dialog open={this.state.showSuccessDialog} onClose={this.toggleSuccessDialog}>
          <DialogContent className='text-center'>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <Avatar src={admin} style={styles.avatar}></Avatar>
            </div>
            <Typography color="primary" variant="h6" className="mb-2">Terima kasih!</Typography>
            <Typography className="mb-4">Tim kami akan segera menghubungi anda.</Typography>
          </DialogContent>
        </Dialog>
      </section>
    );
  }
}

export default Intro;