import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

// import bgimage from 'assets/img/theme/bg01.jpg';

import PlayStore from "../../../components/PlayStore/PlayStore.js";

class Facebook extends Component {
    componentDidMount = () => {
        window.fbAsyncInit = () => {
            window.FB.init({
                appId: '1966884253608276',
                xfbml: true,
                version: 'v7.0',
            });
            window.FB.XFBML.parse();
        };

        (function (d, s, id) {
            const fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            const js = d.createElement(s);
            js.id = id;
            js.async = true;
            js.src = '//connect.facebook.net/en_US/sdk.js';
            fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
    }
    componentDidUpate() {
        if (window.FB) {
            window.FB.XFBML.parse();
        }
    }

    render() {
        // styles
        const styles = {
            section: {
                // backgroundImage: 'url(' + bgimage + ')',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'repeat-x',
                paddingTop: "10vh",
                paddingBottom: "10vh"
            },
            facebookBtn: {
                height: "100%",
                width: "100%",
                background: "#3b5998",
                color: "white",
                fontSize: '1rem',
                whiteSpace: 'nowrap',
            },
            facebookPage: {
                minHeight: "500px", maxWidth: "340px", minWidth: "340px",
                width: '100%',
                borderRadius: '10px',
            },
        }
        return (
            <section className="px-3" style={styles.section} id="fb">
                <Grid container justify="center" alignItems="center" spacing={5}>
                    {/* fb */}
                    <Grid item sm={4} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Card style={styles.facebookPage} elevation={3}>
                            <div id='fb-root'/>
                            <script
                                async
                                defer
                                crossOrigin='anonymous'
                                src='https://connect.facebook.net/id_ID/sdk.js#xfbml=1&version=v12.0'
                                // src='https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v7.0&appId=1966884253608276'
                                // nonce='dJkJ4sHz'
                                nonce='pku1ZpMf'
                                />
                            <div
                                className='fb-page'
                                data-href='https://www.facebook.com/idealku/'
                                data-tabs='timeline'
                                data-width=''
                                data-small-header='false'
                                data-adapt-container-width='true'
                                data-hide-cover='false'
                                data-show-facepile='true'>
                                <blockquote
                                    cite='https://www.facebook.com/idealku/'
                                    className='fb-xfbml-parse-ignore'
                                >
                                <a href='https://www.facebook.com/idealku/'>
                                    iDeal Home Online
                                </a>
                                </blockquote>
                            </div>
                            {/* <iframe
                                title="Facebook iDeal"
                                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fidealku&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                                width="340"
                                height="500"
                                style={{ border: 'none', overflow: 'hidden' }}
                                scrolling="no"
                                frameborder="0"
                                allowfullscreen="true"
                                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe> */}
                        </Card>
                    </Grid>

                    {/* Desc */}
                    <Grid item sm={6}>
                        <Typography variant="h4" color="primary" className="mb-4" style={{ fontWeight: '500' }}>Jangan Ketinggalan Promo Menarik iDeal</Typography>
                        <Typography className="main-text">Tunggu apalagi?</Typography>
                        <Typography className="mb-5 main-text">Segera bergabung bersama iDeal</Typography>
                        {/* action */}
                        <Grid container spacing={1}>
                            <Grid item xs={6} sm={6} md={4} lg={3}>
                                <Button variant="contained" style={{...styles.facebookBtn, maxHeight:'65%'}} href="https://www.facebook.com/idealku" target="_blank" title="Ikuti Kami di Facebook"><i className="fa fa-facebook-official mr-2" aria-hidden="true"></i> Ikuti Kami</Button>
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} lg={3}>
                                <PlayStore userLocation={this.props.userLocation || {}} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </section>
        );
    }
}

export default Facebook;