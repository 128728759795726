import React, { Component } from "react";
class PrivacyPolicy extends Component {
  render() {
    return (
      <div className="pd-20 bg-white" style={{color:'black'}}>
        <p className="text-center m-b-40">
          <strong><span style={{ fontSize: "18px", fontWeight:'Bold'}}>Selamat datang di iDEAL HOME ONLINE</span></strong>
        </p>
        <p>
          <span style={{ fontSize: "14px", fontFamily: "Arial, Helvetica, sans-serif" }}>Persyaratan &amp; Kebijakan Privasi yang ditetapkan di bawah ini mengatur pemakaian jasa jual beli yang ditawarkan oleh iDEAL FUTURA INTERNATIONAL LIMITED terkait penggunaan aplikasi android iDEAL HOME ONLINE. Persyaratan & Kebijakan Privasi ini menggambarkan pengumpulan, penggunaan, dan pengungkapan informasi dari pengguna aplikasi pada pemakaian aplikasi kami. Pengguna disarankan membaca dengan seksama karena dapat berdampak kepada hak dan kewajiban Pengguna di bawah hukum.</span>
        </p>
        <p className="m-b-40">
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Dengan mendaftar dan/atau menggunakan aplikasi android iDEAL HOME ONLINE, maka pengguna dianggap telah membaca, mengerti, memahami dan menyutujui semua isi dalam Syarat &amp; ketentuan. Syarat &amp; ketentuan ini merupakan bentuk kesepakatan yang dituangkan dalam sebuah perjanjian yang sah antara Pengguna dengan iDEAL FUTURA INTERNATIONAL LIMITED. Jika pengguna tidak menyetujui salah satu, sebagian, atau seluruh isi Syarat &amp; ketentuan, maka pengguna tidak diperkenankan menggunakan layanan di iDEAL HOME ONLINE.</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <strong style={{ fontFamily: "Arial,Helvetica,sans-serif", fontWeight:'Bold' }}>A. DEFINISI</strong>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              iDEAL FUTURA INTERNATIONAL LIMITED adalah suatu perseroan terbatas yang menjalankan kegiatan usaha jasa jual barang melalui aplikasi android iDEAL HOME ONLINE, selanjutnya disebut iDEAL.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              Syarat &amp; ketentuan adalah perjanjian antara Pengguna dan iDEAL yang berisikan seperangkat peraturan yang mengatur hak, kewajiban, tanggung jawab pengguna dan iDEAL, serta tata cara penggunaan sistem layanan iDEAL HOME ONLINE.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              Pengguna adalah pihak yang menggunakan layanan iDEAL, termasuk namun tidak terbatas pada pembeli, dan pihak lain yang sekedar berkunjung ke aplikasi iDEAL.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              Pembeli adalah Pengguna terdaftar yang melakukan permintaan atas Barang yang dijual oleh iDEAL.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              Barang adalah produk yang ditawarkan oleh iDEAL, baik berupa benda yang berwujud / memiliki fisik Barang yang dapat diantar / memenuhi kriteria pengiriman oleh perusahaan jasa pengiriman Barang. Maupun transaksi elektronik yang tidak berwujud seperti pulsa, paket data, tiket pesawat, dan lainnya.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              SaldoKu adalah produk digital yang hanya dapat digunakan untuk kegiatan transaksi iDEAL, dan bukan merupakan mata uang/alat pembayaran. Dan tidak dapat diuangkan kembali.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              Supplier adalah pihak ketiga yang bekerja sama dengan iDEAL dalam menjalankan kegiatan usaha iDEAL.
            </span>
          </span>
        </p>
        <p className="m-b-40">
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              OTP adalah One Time Password yang diberikan iDEAL sebagai kode verifikasi terhadap nomor handphone Pengguna.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <strong style={{ fontFamily: "Arial,Helvetica,sans-serif", fontWeight:'Bold' }}>
              B. INFORMASI YANG DIKUMPULKAN
            </strong>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              (i) Informasi Pendaftaran. Jika Anda mendaftar untuk Akun iDeal, kami meminta Anda untuk memberikan informasi pribadi kepada kami, seperti nama Anda, nomor telepon dan pada tahap  selanjutnya nama pengguna, dan kata sandi. Kami juga dapat meminta Anda untuk mempersonalisasi pengalaman pengguna Anda dengan informasi lain seperti kontak Anda, lokasi Anda dan preferensi pribadi Anda. Informasi ini secara kolektif disebut sebagai "Informasi Pendaftaran."
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              (ii) ID Pengguna dan ID Perangkat. Ketika Anda menginstal Aplikasi iDeal, kami mengumpulkan nomor identifikasi unik pada perangkat seluler Anda.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              (iii) Informasi Penggunaan. Kami juga mengumpulkan informasi tentang bagaimana Anda menggunakan Layanan kami, termasuk bagaimana Anda melihat dan berinteraksi dengan konten, bagian-bagian dari Layanan kami yang Anda gunakan, informasi yang Anda cari, konten yang Anda lihat, dan tindakan yang Anda lakukan.
            </span>
          </span>
        </p>
        <p className="m-b-40">
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              (iv) Mencatat Data. Ketika Anda menggunakan Layanan kami, kami secara otomatis mencatat informasi tentang penggunaan Anda ("Log Data"). Data Log ini dapat mencakup informasi seperti alamat Protokol Internet ("IP") komputer atau perangkat seluler Anda, waktu dan tanggal akses dan statistik lainnya.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <strong style={{ fontFamily: "Arial,Helvetica,sans-serif", fontWeight:'Bold' }}>
              C. BAGAIMANA KAMI MENGGUNAKAN INFORMASI YANG DIKUMPULKAN
            </strong>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              (i) Kami menggunakan informasi yang kami kumpulkan, termasuk Informasi Pendaftaran, ID Pengguna dan ID Perangkat Anda, informasi dari penggunaan untuk menyediakan dan meningkatkan Layanan kami, mengembangkan penawaran baru dan meningkatkan dan mempersonalisasikan pengalaman Anda di iDeal, termasuk konten dan iklan yang Anda lihat.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              (ii) Informasi yang Anda Bagikan. Penggunaan aplikasi iDeal dirancang untuk tujuan pribadi, tidak ada apa pun yang Anda ungkapkan tersedia untuk orang lain.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              (iii) Dengan Persetujuan Anda. Kami dapat mengungkapkan informasi apa pun yang kami kumpulkan dengan pihak ketiga ketika kami menerima persetujuan Anda untuk melakukannya melalui Persyaratan dan Kebijakan Privasi ini.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              (iv) Penyedia Layanan. Kami mempekerjakan pihak ketiga untuk membantu kami dalam menyediakan dan meningkatkan Layanan (mis., Tanpa batasan, pemeliharaan, marketing dan manajemen basis data, dan perusahaan analisis web). Jika pihak ketiga ini menerima informasi pribadi non-publik tentang Anda, mereka akan diwajibkan untuk tidak mengungkapkan atau menggunakannya untuk tujuan apa pun selain untuk melakukan tugas atas nama kami.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              (v) Kepatuhan dengan Hukum dan Penegakan Hukum. iDeal bekerja sama dengan pejabat pemerintah dan penegak hukum serta pihak swasta untuk menegakkan dan mematuhi hukum. Kami akan mengungkapkan informasi tentang Anda kepada pejabat pemerintah atau penegak hukum atau pihak swasta karena kami, atas pertimbangan kami sendiri, percaya perlu atau sesuai untuk menanggapi klaim dan proses hukum (termasuk tetapi tidak terbatas pada panggilan pengadilan), untuk melindungi properti dan hak-hak iDeal atau pihak ketiga, untuk melindungi keselamatan publik atau siapa pun atau untuk mencegah atau membatasi apa yang kami anggap sebagai, risiko dari kegiatan ilegal, tidak etis, atau kegiatan yang dapat ditindaklanjuti secara hukum.
            </span>
          </span>
        </p>
        <p className="m-b-40">
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              (vi) Transfer Bisnis. iDeal dapat menjual, mentransfer, atau membagikan sebagian atau seluruh asetnya, termasuk informasi yang diidentifikasi dalam Kebijakan Privasi ini, sehubungan dengan merger, akuisisi, reorganisasi, atau penjualan aset atau jika terjadi kebangkrutan.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <strong style={{ fontFamily: "Arial,Helvetica,sans-serif", fontWeight:'Bold' }}>
              D. AKUN, SALDO iDEAL, MEMBERSHIP LEVEL, OTP (ONE TIME PASSWORD) DAN KEAMANAN
            </strong>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              Saldo iDEAL adalah salah satu produk digital dari iDEAL, dan bukan alat pembayaran diluar iDEAL. Saldo iDEAL dapat digunakan terbatas untuk kegiatan membeli barang melalui aplikasi iDEAL, selanjutnya disebut SaldoKu.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              SaldoKu tidak dapat diuangkan, ataupun dipindah, ataupun diganti fungsi dalam bentuk dan kepada siapa pun diluar iDEAL.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              Jika ada transaksi dari iDEAL yang dibatalkan, maka jumlah dana dari transaksi tersebut hanya akan dikembalikan ke SaldoKu pengguna.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              iDEAL berhak, tanpa pemberitahuan sebelumnya, melakukan tindakan-tindakan yang diperlukan apabila ditemukan tindakan manipulasi/kecurangan, seperti menutup akun, menarik bonus, menarik cashback, membatalkan transaksi, menahan SaldoKu, menurunkan level membership, serta hal-hal lainnya jika ditemukan indikasi kecurangan atau pelanggaran pelanggaran Syarat dan Ketentuan iDEAL dan ketentuan hukum yang berlaku di wilayah negara Hong Kong SAR dan Republik Indonesia.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              Pengguna di iDEAL dapat meningkatkan level member ke GOLD dan DIAMOND apabila memenuhi persyaratan yang diberikan oleh iDEAL.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              Member GOLD memiliki fasilitas Pay Later, dimana pembeli diberikan kebebasan untuk membayar setelah barang diterima.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              Member DIAMOND memiliki fasilitas Cicilan, dimana jumlah dari harga pembelian dapat dicicil beberapa kali sesuai dengan ketentuan dari iDEAL.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              Pengguna dengan ini menyatakan bahwa pengguna adalah orang yang cakap dan mampu untuk mengikatkan dirinya dalam sebuah perjanjian yang sah menurut hukum.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              iDEAL tidak memungut biaya pendaftaran kepada Pengguna.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              Untuk pendaftaran akun, pengguna harus melakukan verifikasi nomor handphone terlebih dulu.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              iDEAL tanpa pemberitahuan terlebih dahulu kepada Pengguna, memiliki kewenangan untuk melakukan tindakan yang perlu atas setiap dugaan pelanggaran atau pelanggaran Syarat &amp; ketentuan dan/atau hukum yang berlaku, yakni tindakan berupa membatalkan transaksi pembelian, penghapusan Barang, moderasi akun, suspensi akun, dan/atau penghapusan akun pengguna.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              iDEAL memiliki kewenangan untuk menutup akun Pengguna baik sementara maupun permanen apabila didapati adanya tindakan kecurangan dalam bertransaksi dan/atau pelanggaran terhadap syarat dan ketentuan iDEAL.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              Pengguna dilarang untuk menciptakan dan/atau menggunakan perangkat, software, fitur dan/atau alat lainnya yang bertujuan untuk melakukan manipulasi pada sistem iDEAL, termasuk namun tidak terbatas pada :
            </span>
          </span>
        </p>
        <p className="m-b-40">
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              (i) manipulasi data pengguna;<br />
              (ii) kegiatan perambanan (crawling/scraping);<br />
              (iii) aktivitas untuk mengakses iDEAL dengan program lain dengan tujuan apapun;<br />
              (iv) kegiatan otomatisasi dalam transaksi, beli, promosi, dsb;<br />
              (v) perubahan level member secara curang, manipulasi data, dsb;<br />
              (vi) penambahan saldo ke SaldoKu; dan/atau<br />
              (vii) aktivitas lain yang secara wajar dapat dinilai sebagai tindakan manipulasi sistem.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              iDEAL memiliki kewenangan untuk melakukan pembekuan SaldoKu Pengguna apabila ditemukan / diduga adanya tindak kecurangan dalam bertransaksi dan/atau pelanggaran terhadap syarat dan ketentuan iDEAL.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              Pengguna dilarang melakukan pengunggahan dokumen palsu, atau tindakan-tindakan lain yang dapat diindikasikan sebagai usaha curang untuk mengaburkan identitas asli.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              Pengguna tidak memiliki hak untuk mengubah nomor handphone yang terdaftar.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              Pengguna bertanggung jawab secara pribadi untuk menjaga kerahasiaan akun dan dokumen untuk semua aktivitas yang terjadi dalam akun Pengguna.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              Pengguna dilarang untuk memberikan data pribadi maupun data penting lainnya kepada pihak yang mengatasnamakan iDEAL atau pihak lain yang tidak dapat dijamin keamanannya.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              Pengguna setuju untuk memastikan bahwa Pengguna bertanggung jawab atas akun di akhir setiap sesi dan memberitahu iDEAL jika ada penggunaan tanpa izin atas sandi atau akun Pengguna.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              Pengguna dengan ini menyatakan bahwa iDEAL TIDAK bertanggung jawab atas kerugian ataupun kendala yang timbul atas penyalahgunaan akun Pengguna yang diakibatkan oleh kelalaian Pengguna, termasuk namun tidak terbatas pada meminjamkan atau memberikan akses akun atau handphone kepada pihak lain, mengakses link atau tautan yang diberikan oleh pihak lain, memberikan atau memperlihatkan kode verifikasi (OTP), nomor handphone atau identitas lain kepada pihak lain, maupun kelalaian Pengguna lainnya yang mengakibatkan kerugian ataupun kendala pada akun Pengguna.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              Pengguna memahami dan menyetujui bahwa satu nomor handphone yang ter verifikasi hanya dapat digunakan untuk satu akun iDEAL, dan Pengguna bertanggung jawab atas akun tersebut. Apabila terjadi kehilangan atau kecurangan atas akun tersebut, maka hal tersebut merupakan tanggung jawab dari pengguna sendiri.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              Pengguna memahami dan menyetujui bahwa untuk mempergunakan fasilitas keamanan one time password (OTP) maka penyedia jasa telekomunikasi terkait dapat sewaktu-waktu mengenakan biaya kepada Pengguna dengan nominal yang ditetapkan oleh operator telekomunikasi terkait.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              Pengguna dengan ini menyetujui untuk memberikan hak kepada iDEAL untuk mengintegrasikan dan/atau mengalihkan penyediaan layanan kepada pihak ketiga yang bekerja sama dengan iDEAL yang bertujuan untuk memudahkan Pengguna dalam bertransaksi.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              Pengguna memahami, menyetujui dan memberikan wewenang kepada iDEAL untuk melakukan tindakan wajar yang diperlukan sehubungan dengan akun milik Pengguna, yang termasuk namun tidak terbatas pada melakukan integrasi data seperti informasi kontak, lokasi, nomor telepon Pengguna, akun, dana, dan/atau informasi Pengguna kepada pihak ketiga dimaksud.
            </span>
          </span>
        </p>
        <p className="m-b-40">
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              Pengguna dengan ini sepenuhnya memahami bahwa dengan disetujuinya Syarat dan Ketentuan ini, maka integrasi yang dilakukan oleh iDEAL dari waktu ke waktu adalah dilakukan sesuai dengan ketentuan yang tercantum di dalam Persyaratan &amp; Kebijakan Privasi.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <strong style={{ fontFamily: "Arial,Helvetica,sans-serif", fontWeight:'bold' }}>E. TRANSAKSI PEMBELIAN</strong>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              Pembeli wajib bertransaksi melalui prosedur transaksi yang telah ditetapkan oleh iDEAL. Pembeli melakukan pembayaran dengan menggunakan metode pembayaran yang disediakan iDEAL dan sebelumnya telah dipilih oleh Pembeli.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              Saat melakukan pembelian Barang, Pembeli menyetujui bahwa:
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
              - Pembeli bertanggung jawab untuk membaca, memahami, dan menyetujui informasi/deskripsi keseluruhan Barang (termasuk didalamnya namun tidak terbatas pada warna, kualitas, fungsi, dan lainnya) sebelum melakukan pembelian.
            </span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>- Pembeli mengakui bahwa warna sebenarnya dari produk sebagaimana terlihat di iDEAL tergantung pada resolusi handphone Pembeli. iDEAL telah melakukan upaya terbaik untuk memastikan warna dalam foto-foto yang ditampilkan di aplikasi iDEAL muncul seakurat mungkin, tetapi tidak dapat menjamin bahwa penampilan warna akan akurat.</span></span> <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>- Pengguna masuk ke dalam kontrak yang mengikat secara hukum untuk membeli Barang ketika Pengguna membeli suatu barang.</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>- iDEAL tidak mengalihkan kepemilikan secara hukum atas barang-barang dari supplier kepada Pembeli.</span></span><span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Pembeli memahami dan menyetujui bahwa ketersediaan stok Barang merupakan tanggung jawab supplier barang. Terkait ketersediaan stok Barang dapat berubah sewaktu-waktu, sehingga dalam keadaan stok Barang kosong, maka iDEAL memiliki hak utk menolak order, dan pembayaran atas barang yang bersangkutan dikembalikan kepada Pembeli dengan SaldoKu.</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>iDEAL memiliki kewenangan sepenuhnya untuk menolak pembayaran tanpa pemberitahuan terlebih dahulu.</span></span><span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Pembayaran oleh Pembeli wajib dilakukan segera (selambat-lambatnya dalam batas waktu 2 hari) setelah Pembeli melakukan pemesanan. Jika dalam batas waktu tersebut pembayaran atau konfirmasi pembayaran belum dilakukan oleh pembeli, iDEAL memiliki kewenangan untuk membatalkan transaksi dimaksud. Pengguna tidak berhak mengajukan klaim atau tuntutan atas pembatalan transaksi tersebut.</span></span> <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Dalam hal terjadi kerugian akibat pemberitahuan atau penyerahan bukti pembayaran dan/atau data pembayaran oleh Pembeli kepada pihak lain, maka hal tersebut akan menjadi tanggung jawab Pembeli sendiri.</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Pembeli memahami dan menyetujui bahwa setiap masalah pengiriman Barang yang disebabkan keterlambatan pembayaran adalah merupakan tanggung jawab dari Pembeli.</span></span> <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Pengguna memahami dan menyetujui bahwa iDEAL tidak dapat mengembalian transaksi dalam bentuk uang. Apabila terjadi pengembalian dana, maka iDEAL hanya dapat mengembalikan dalam bentuk SaldoKu, dengan begitu pengguna dapat melakukan transaksi lainnya dengan SaldoKu.</span></span> <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Pengembalian SaldoKu dari iDEAL kepada Pembeli hanya dapat dilakukan jika dalam keadaan- keadaan tertentu berikut ini:</span></span><span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>- Kelebihan pembayaran dari Pembeli atas haarga Barang,</span></span><span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span> <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp;&nbsp;</span></span><span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>- Masalah pengiriman Barang tidak teridentifikasi secara jelas dari supplier yang mengakibatkan pesanan Barang tidak sampai,</span></span><span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>- Supplier tidak bisa menyanggupi order karena kehabisan stok, selisih harga, maupun penyebab lainnya,</span></span><span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>- Supplier sudah menyanggupi pengiriman order Barang, tetapi setelah batas waktu yang ditentukan ternyata Supplier tidak mengirimkan Barang hingga batas waktu yang telah ditentukan.</span></span> <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>- Penyelesaian permasalahan melalui service hotline iDEAL berupa keputusan untuk pengembalian dana kepada Pembeli atau hasil keputusan dari pihak iDEAL.</span></span> <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Apabila terjadi proses pengembalian dana, maka pengembalian akan dilakukan melalui Saldoku milik Pengguna yang akan bertambah sesuai dengan jumlah pengembalian dana.</span></span> <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p className="m-b-40">
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>iDEAL berwenang mengambil keputusan atas permasalahan-permasalahan transaksi yang belum terselesaikan akibat tidak adanya kesepakatan penyelesaian, keputusan iDEAL adalah keputusan akhir yang tidak dapat diganggu gugat dan mengikat pihak pengguna untuk mematuhinya.</span></span>
        </p><span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        <p>
          <span style={{ fontSize: "14px" }}>
            <strong style={{ fontFamily: "Arial,Helvetica,sans-serif", fontWeight:'bold' }}>
              F. CASHBACK (REFERRAL)
            </strong>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>iDEAL memberikan fasilitas Referral bagi pengguna yang menawarkan produk iDEAL kepada pengguna lain. Bonus referral dapat berupa Saldoku, kupon discount, atau bentuk lainnya sesuai dengan ketentuan yang diberikan iDEAL.</span></span> <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp;&nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Pengguna memahami dan menyetujui bahwa semua bentuk promo yang diberikan merupakan kewenangan iDEAL dan tidak dapat diuangkan.</span></span>
        </p><span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        <p>
          <span style={{ fontSize: "14px" }}>
            <strong style={{ fontFamily: "Arial,Helvetica,sans-serif", fontWeight:'bold' }}>
              G. HARGA
            </strong>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Harga Barang yang terdapat dalam aplikasi iDEAL adalah harga dalam mata uang Hong Kong Dollar. Pengguna dilarang memanipulasi harga barang dengan cara apapun.</span></span> <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Pembeli memahami dan menyetujui bahwa kesalahan keterangan harga dan informasi lainnya yang disebabkan tidak terbaharuinya halaman situs iDEAL dikarenakan browser/ISP yang dipakai Pembeli adalah tanggung jawab Pembeli.</span></span> <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Apabila terjadi kesalahan pengetikan keterangan harga Barang yang tidak disengaja, iDEAL berhak menolak pesanan Barang yang dilakukan oleh pembeli.</span></span> <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Dengan melakukan pemesanan melalui iDEAL, Pengguna menyetujui untuk membayar total biaya yang harus dibayarkan sebagaimana tertera dalam halaman pembayaran, yang terdiri dari harga barang dan biaya-biaya lain yang mungkin timbul dan akan diuraikan secara tegas dalam halaman pembayaran.</span></span> <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Pengguna setuju untuk melakukan pembayaran melalui metode pembayaran yang disediakan oleh iDEAL dan yang telah dipilih sebelumnya oleh Pengguna.</span></span><span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp;</span></span><span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><strong style={{ fontFamily: "Arial,Helvetica,sans-serif", fontWeight:'bold' }}>H. PROSEDUR PENGIRIMAN</strong></span><span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Pembeli memahami dan menyetujui bahwa semua transaksi pembelian barang akan dikirim oleh pihak ketiga yang bekerja sama dengan iDEAL.</span></span><span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Setiap ketentuan berkenaan dengan proses pengiriman Barang adalah wewenang sepenuhnya penyedia jasa layanan pengiriman Barang.</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Pengguna memahami dan menyetujui bahwa setiap permasalahan yang terjadi pada saat proses pengiriman Barang oleh penyedia jasa layanan pengiriman Barang adalah merupakan tanggung jawab penyedia jasa layanan pengiriman.</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Dalam hal diperlukan untuk dilakukan proses pengembalian barang, maka Pengguna diwajibkan untuk melakukan pengiriman barang langsung ke supplier atau distributor merk produk terkait. iDEAL tidak menerima pengembalian barang atas transaksi yang dilakukan oleh Pengguna dalam kondisi apapun.</span></span> <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Pembeli memahami dan mengerti bahwa iDEAL telah melakukan usaha sebaik mungkin dalam memberikan informasi tarif pengiriman kepada Pembeli berdasarkan lokasi secara akurat, namun iDEAL tidak dapat menjamin keakuratan data tersebut dengan yang ada pada cabang setempat.</span></span> <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Apabila mendapati perbedaan, mohon sekiranya untuk menginformasikan kepada kami melalui menu Customer Service dengan memberikan data harga yang didapat beserta kota asal dan tujuan, agar dapat kami telusuri lebih lanjut.</span></span> <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Apabila terjadi perselisihan di dalam pengiriman dan atau penerimaan barang, maka iDEAL berhak untuk membatakan transaksi tersebut, dan semua pembayaran yang telah dilakukan oleh Pembeli tidak dapat diuangkan kembali.</span></span> <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Dalam kasus dan tidak terbatas pada kasus ini, iDEAL akan melakukan investigasi dan apabila terjadi pengembalian dana, hanya berupa SaldoKu.</span></span><span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Dalam hal terjadi kendala dalam proses pengiriman berupa barang hilang, barang rusak, dan lain sebagainya, Pembeli dapat melaporkan ke pihak iDEAL paling lambat 3x24 jam sejak waktu pengiriman untuk dilakukan proses investigasi.</span></span>
        </p><span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        <p>
          <span style={{ fontSize: "14px" }}><strong style={{ fontFamily: "Arial,Helvetica,sans-serif", fontWeight:'bold' }}>I. PROSEDUR PENARIKAN</strong></span><span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Member GOLD dan DIAMOND yang melanggaran aturan pembayaran, telat, tidak membayar pada saat jatuh tempo, gagal bayar, dan lain sebagainya yang mengakibatkan pembayaran tidak terpenuhi, maka iDEAL dapat memberikan data pengguna dan transaksi pengguna ke pihak ketiga untuk dilakukan penarikan barang.</span></span> <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Pengguna memahami dan menyetujui dengan semua kebijakan pihak ketiga yang bekerja sama dengan iDEAL dalam menangani penarikan barang apabila terjadi gagal bayar.</span></span> <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Semua kebijakan dan prosedur pihak ketiga dalam melaksanakan aktivitas penarikan barang merupakan tanggung jawab pihak ketiga, bukan tanggung jawab iDEAL.</span></span> <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></span> <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp;&nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><strong style={{ fontFamily: "Arial,Helvetica,sans-serif", fontWeight:'bold' }}>J. KONTEN</strong></span><span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Dalam menggunakan setiap fitur dan/atau layanan iDEAL, Pengguna dilarang untuk mengunduh atau mempergunakan kembali kata-kata, komentar, gambar, atau konten apapun untuk tujuan yang mengandung unsur SARA, diskriminasi, merendahkan atau menyudutkan orang lain, vulgar, bersifat ancaman, atau hal-hal lain yang dapat dianggap tidak sesuai dengan nilai dan norma sosial.</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>iDEAL berhak melakukan tindakan yang diperlukan atas pelanggaran ketentuan ini, antara lain penghapusan konten, moderasi akun, pemblokiran akun, dan lain-lain.</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Pengguna dilarang mempergunakan foto/gambar Barang yang memiliki watermark yang menandakan hak kepemilikan orang lain untuk kepentingan lain.</span></span><span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Penguna dengan ini memahami dan menyetujui bahwa penyalahgunaan foto/gambar yang di unduh oleh Pengguna adalah tanggung jawab Pengguna secara pribadi.</span></span> <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Setiap Pengguna dengan ini bertanggung jawab secara pribadi atas pelanggaran hak kekayaan intelektual dalam mengunduh konten iDEAL.</span></span><span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Meskipun kami mencoba untuk menawarkan informasi yang dapat diandalkan, kami tidak bisa menjanjikan bahwa katalog akan selalu akurat dan terbarui, dan Pengguna setuju bahwa Pengguna tidak akan meminta iDEAL bertanggung jawab atas ketimpangan dalam katalog. Katalog mungkin termasuk hak cipta, merek dagang atau hak milik lainnya.</span></span> <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>iDEAL berhak untuk sewaktu-waktu menurunkan konten atau materi yang terdapat pada aplikasi iDEAL yang dianggap melanggar Syarat dan Ketentuan Situs, peraturan hukum yang berlaku, serta etika pariwara yang berlaku.</span></span>
        </p><span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        <p>
          <span style={{ fontSize: "14px" }}><strong style={{ fontFamily: "Arial,Helvetica,sans-serif", fontWeight:'bold' }}>K. JENIS BARANG</strong></span><span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Pengguna memahami dan menyetujui bahwa semua barang yang disediakan iDEAL adalah dari supplier yang bekerja sama dengan iDEAL.</span></span><span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>iDEAL tidak bertanggung jawab terhadap semua efek samping, dan atau salah penggunaan yang mengakibatkan kerugian pada pembeli.</span></span>
        </p><span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        <p>
          <span style={{ fontSize: "14px" }}><strong style={{ fontFamily: "Arial,Helvetica,sans-serif", fontWeight:'bold' }}>L. PROMO</strong></span><span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>iDEAL sewaktu-waktu dapat mengadakan kegiatan promosi (selanjutnya disebut sebagai &ldquo;Promo&rdquo;) dengan Syarat dan Ketentuan yang mungkin berbeda pada masing-masing kegiatan Promo. Pengguna dihimbau untuk membaca dengan seksama Syarat dan Ketentuan Promo tersebut.</span></span> <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>iDEAL berhak, tanpa pemberitahuan sebelumnya, melakukan tindakan-tindakan yang diperlukan termasuk namun tidak terbatas pada menarik subsidi atau cashback, membatalkan benefit, pencabutan Promo, membatalkan transaksi, menahan SaldoKu, menurunkan membership level, menutup akun, serta hal-hal lainnya jika ditemukan adanya manipulasi, penggunaan aplikasi pihak ketiga ilegal, pelanggaran maupun pemanfaatan Promo untuk keuntungan pribadi Pengguna, maupun indikasi kecurangan atau pelanggaran Persyaratan &amp; Kebijakan Privasi iDEAL dan ketentuan hukum yang berlaku di wilayah negara Hong Kong SARA dan Republik Indonesia.</span></span>
        </p><span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span> <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        <p>
          <span style={{ fontSize: "14px" }}><strong style={{ fontFamily: "Arial,Helvetica,sans-serif", fontWeight:'bold' }}>M. KETENTUAN LAIN</strong></span><span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Apabila Pengguna mempergunakan fitur-fitur yang tersedia dalam aplikasi iDEAL, maka Pengguna dengan ini menyatakan memahami dan menyetujui segala syarat dan ketentuan yang diatur khusus sehubungan dengan fitur-fitur tersebut.</span></span> <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Segala hal yang belum dan/atau tidak diatur dalam syarat dan ketentuan khusus dalam fitur tersebut maka akan sepenuhnya merujuk pada syarat dan ketentuan iDEAL secara umum.</span></span>
        </p><span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        <p>
          <span style={{ fontSize: "14px" }}><strong style={{ fontFamily: "Arial,Helvetica,sans-serif", fontWeight:'bold' }}>N. PENOLAKAN JAMINAN DAN BATASAN TANGGUNG JAWAB</strong></span><span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>iDEAL selalu berupaya untuk menjaga Layanan yang aman, nyaman, dan berfungsi dengan baik, tapi kami tidak dapat menjamin operasi terus-menerus atau akses ke Layanan kami dapat selalu sempurna. Informasi dan data dalam aplikasi iDEAL memiliki kemungkinan tidak terjadi secara real time.</span></span> <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Pengguna setuju bahwa Anda memanfaatkan Layanan iDEAL atas risiko Pengguna sendiri, dan Layanan iDEAL diberikan kepada Anda pada &quot;SEBAGAIMANA ADANYA&quot; dan &quot;SEBAGAIMANA TERSEDIA&quot;.</span></span> <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Sejauh diizinkan oleh hukum yang berlaku, iDEAL (termasuk Induk Perusahaan, direktur, dan karyawan) adalah tidak bertanggung jawab, dan Anda setuju untuk tidak menuntut iDEAL bertanggung jawab, atas segala kerusakan atau kerugian (termasuk namun tidak terbatas pada hilangnya uang, reputasi, keuntungan, atau kerugian tak berwujud lainnya) yang diakibatkan secara langsung atau tidak langsung dari :</span></span> <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>
            - Penggunaan atau ketidakmampuan Pengguna dalam menggunakan Layanan iDEAL.<br />
            - Harga, Pengiriman atau petunjuk lain yang tersedia dalam layanan iDEAL.<br />
            - Keterlambatan atau gangguan dalam Layanan iDEAL.<br />
            - Kelalaian dan kerugian yang ditimbulkan oleh Pengguna.<br />
            - Kualitas Barang.<br />
            - Pengiriman Barang.<br />
            - Pelanggaran Hak atas Kekayaan Intelektual.<br />
            - Pencemaran nama baik pihak lain.<br />
            - Setiap penyalahgunaan Barang yang sudah dibeli pihak Pengguna.<br />
            - Kerugian akibat pembayaran tidak resmi kepada pihak lain selain ke Rekening Resmi iDEAL, yang dengan cara apa pun mengatas-namakan iDEAL.<br />
            - Pengiriman untuk perbaikan Barang yang bergaransi resmi dari pihak produsen. Pembeli dapat membawa Barang langsung kepada pusat layanan servis terdekat dengan kartu garansi dan faktur pembelian.<br />
            - Virus atau perangkat lunak berbahaya lainnya (bot, script, automation tool, hacking tool) yang diperoleh dengan mengakses, atau menghubungkan ke layanan iDEAL.<br />
            - Gangguan, bug, kesalahan atau ketidakakuratan apapun dalam Layanan iDEAL. - Kerusakan pada perangkat keras Anda dari penggunaan setiap Layanan iDEAL.<br />
            - Isi, tindakan, atau tidak adanya tindakan dari pihak ketiga, termasuk terkait dengan Produk yang ada dalam aplikasi iDEAL yang diduga palsu.<br />
            - Tindak penegakan yang diambil sehubungan dengan akun Pengguna.<br />
            - Adanya tindakan peretasan yang dilakukan oleh pihak ketiga kepada akun pengguna<br />
          </span></span>
        </p><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
        <p>
          <span style={{ fontSize: "14px" }}><strong style={{ fontFamily: "Arial,Helvetica,sans-serif", fontWeight:'bold' }}>O. GANTI RUGI</strong></span><span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Pengguna akan melepaskan iDEAL dari tuntutan ganti rugi dan menjaga iDEAL (termasuk Induk Perusahaan, direktur, dan karyawan) dari setiap klaim atau tuntutan, termasuk biaya hukum yang wajar, yang dilakukan oleh pihak ketiga yang timbul dalam hal Anda melanggar Perjanjian ini, penggunaan Layanan iDEAL yang tidak semestinya dan/ atau pelanggaran Anda terhadap hukum atau hak-hak pihak ketiga.</span></span>
        </p><span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        <p>
          <span style={{ fontSize: "14px" }}><strong style={{ fontFamily: "Arial,Helvetica,sans-serif", fontWeight:'bold' }}>P. PILIHAN HUKUM</strong></span><span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp;</span></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Perjanjian ini akan diatur oleh dan ditafsirkan sesuai dengan hukum pemerintahan Hong Kong SAR dan Republik Indonesia, tanpa memperhatikan pertentangan aturan hukum. Pengguna setuju bahwa tindakan hukum apapun atau sengketa yang mungkin timbul dari, berhubungan dengan, atau berada dalam cara apapun berhubungan dengan situs dan/atau Perjanjian ini akan diselesaikan secara eksklusif dalam yurisdiksi pengadilan Hong Kong SAR dan Republik Indonesia.</span></span>
        </p><span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span>
        <p>
          <span style={{ fontSize: "14px" }}><strong style={{ fontFamily: "Arial,Helvetica,sans-serif", fontWeight:'bold' }}>Q. PEMBAHARUAN &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</strong></span>
        </p>
        <p>
          <span style={{ fontSize: "14px" }}><span style={{ fontFamily: "Arial,Helvetica,sans-serif" }}>Syarat &amp; ketentuan mungkin di ubah dan/atau diperbaharui dari waktu ke waktu tanpa pemberitahuan sebelumnya. iDEAL menyarankan agar anda membaca secara seksama dan memeriksa halaman Syarat &amp; ketentuan ini dari waktu ke waktu untuk mengetahui perubahan apapun. Dengan tetap mengakses dan menggunakan layanan iDEAL, maka pengguna dianggap menyetujui perubahan-perubahan dalam Syarat &amp; ketentuan.</span></span>
        </p>
      </div>
    );
  }
}

export default PrivacyPolicy;