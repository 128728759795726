import React, { Component } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';

import Logo from 'assets/img/main/logo.png';
import fb from 'assets/img/icons/facebook.svg';
import ig from 'assets/img/icons/instagram.svg';
import wa from 'assets/img/icons/whatsapp.svg';
import yt from 'assets/img/icons/youtube.svg';
import line from 'assets/img/icons/line.svg';

import { Link } from "react-router-dom";
// import LogoWhite from 'assets/img/main/logo_white.png';

let adminUrl = null;
let isTW;
let hide = true;
class HomeNavBar extends Component {
    constructor(props) {
        super(props);
        this.listener = null;
        this.state = {
            navType: 0, // 0 : transparant 1: colored
        }
    }
    componentDidMount = () => {
        this.getIsTW()
        this.listener = document.addEventListener("scroll", () => {
            // change when scroll 20% of height screen
            let scrolled = document.scrollingElement.scrollTop;
            if (scrolled >= (window.innerHeight * 5 / 100)) {
                this.setState({
                    navType: 1,
                });
            } else {
                this.setState({
                    navType: 0,
                });
            }
        });
    }
    componentDidUpdate() {
        document.removeEventListener("scroll", this.listener);
    }
    getIsTW = async () => {
        let promiseConfig = await require("../../config/config.js").then(function (e) {
            console.log(e)
            return e
        });
        adminUrl = promiseConfig.adminUrl
        if ((adminUrl === "https://apiprod-tw.i-dealhomeonline.com/admin") || (adminUrl === "https://apidev-tw.i-dealhomeonline.com/admin")) {
            isTW = true
        }
        hide = false
    }

    // view
    render() {
        const style = {
            colorPrimary: {
                color: '#fb6340',
                fontWeight: 'bold'
            },
            colorWhite: {
                color: '#2d2d2d',
                fontWeight: 'bold'
            },
            bgTransparent: {
                background: 'transparent',
                transition: "0.5s"
            },
            bgMain: {
                background: 'white',
                transition: "0.5s"
            },
            logo: {
                background: `url(${Logo}) no-repeat left center`,
                backgroundSize: 'contain',
                padding: "8px 0",
                height: 50
            },
        }
        const navStatus = {
            color: this.state.navType === 0 ? "transparent" : "transparent",
            elevation: this.state.navType === 0 ? 0 : 2,
            toolbar: this.state.navType === 0 ? style.bgTransparent : style.bgMain,
            navItem: this.state.navType === 0 ? style.colorWhite : style.colorWhite,
        }
        const listLink = [
            {
                link: '/home',
                linkName: 'Home'
            },
            {
                link: '/service',
                linkName: 'Service'
            },
            {
                link: '/testimonial',
                linkName: 'Testimonial'
            },
            {
                link: '/location',
                linkName: 'Location'
            },
        ]
        return (
            <>
                <AppBar position="fixed" elevation={navStatus.elevation} style={navStatus.toolbar}>
                    <Toolbar>
                        <Grid container justify="space-between" alignItems="center">
                            {/* left side */}
                            <Grid item>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <a href="/" title="iDeal Home Online" style={{ width: '100px', height: 'auto', display: "block" }}>
                                            <div
                                                className='img-responsive '
                                                style={style.logo}
                                            />
                                        </a>
                                    </Grid>
                                    <Grid item>
                                        <Hidden smDown>  {/* hide in moblie */}
                                            {listLink && listLink.map((item, index) => {
                                                return (
                                                    <Link to={item.link} key={index} onClick={() => this.props.linkCallback(item.link)}><Button style={navStatus.navItem} className="ml-4">{item.linkName}</Button></Link>
                                                )
                                            })}
                                        </Hidden>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* right side */}
                            <Grid item>
                                <a target="blank" href='https://www.facebook.com/idealku' title="Facebook" className="mr-3"><img width="25" src={fb} alt="facebook"></img></a>
                                <a target="blank" href='https://www.instagram.com/idealhomeonline/' title="Instagram" className="mr-3"><img width="25" src={ig} alt="instagram"></img></a>
                                <a target="blank" href="https://www.youtube.com/channel/UCRTh7Exeg9AEdihsxwj1QRw" title="Youtube" className="mr-3"><img width="30" src={yt} alt="youtube"></img></a>
                                {hide ? "" : isTW ?
                                    <a target="blank" href="https://line.me/R/ti/p/@idealhomeonline" title="Line"><img width="30" src={line} alt="Line"></img></a>
                                    :
                                    <a target="blank" href="https://wa.me/85229935000" title="Whatsapp"><img width="30" src={wa} alt="WhatsApp"></img></a>
                                }
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </>
        );
    }
}

export default HomeNavBar;