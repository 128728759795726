import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
// img
import HotLineHk from '../../assets/img/theme/CS-HK.png';
import HotLineTw from '../../assets/img/theme/CS-TW.png';
import mtrHK from '../../assets/img/theme/mtr-hk_small.png';
import mrtTW from '../../assets/img/theme/mrt-tw_small.png';
// component
import PlayStore from "../PlayStore/PlayStore.js";


let adminUrl = null;
let isTW = false;

class HomeFooter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            height: '50px'
        }
    }
    componentDidMount = async () => {
        let promiseConfig = await require("../../config/config.js").then(function (e) {
            console.log(e)
            return e
        });
        adminUrl = promiseConfig.adminUrl
        if ((adminUrl === "https://apiprod-tw.i-dealhomeonline.com/admin") || (adminUrl==="https://apidev-tw.i-dealhomeonline.com/admin")) {
            isTW = true
        }

        const height = document.getElementById('ideal-location').clientHeight;
        const heighttag = document.getElementById('ideal-tag').clientHeight;
        this.setState({ height: height > heighttag ? height : heighttag });

    }    
    // view
    render() {
        // styles
        const styles = {
            section: {
                paddingTop: "10vh",
                paddingBottom: "5vh"
            },
            hotLineHk: {
                display: 'block',
                maxWidth: '190px',
                // marginTop: '8px',
                marginBottom: '16px',
                background: `url(${HotLineHk}) no-repeat top`,
                backgroundSize: 'contain',
                height: 'auto',
                height: 100,
            },
            hotLineTw: {
                display: 'block',
                maxWidth: '218px',
                // marginTop: '8px',
                marginBottom: '16px',
                background: `url(${HotLineTw}) no-repeat top`,
                backgroundSize: 'contain',
                height: 100,
            },
            bgColor: {
                background: '#fdc0724d',
            },
            mrt: {
                backgroundImage: `url(${mtrHK})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                display: 'inline-block',
                width: '25px',
                height: '14px',
                marginBottom: '-1.5px',
            },
            mrtTw: {
                backgroundImage: `url(${mrtTW})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                display: 'inline-block',
                width: '25px',
                height: '14px',
                marginBottom: '-1.5px',
            }
        }
        return (
            <footer className="pb-3" id="location" style={styles.bgColor}>
                <Grid container justify="center" style={styles.section} className={window.innerWidth > 600 ? '' : 'px-3'}>
                    <Grid item sm={10} container spacing={5} justify='center'>
                        {/* 1 - map */}
                        <Grid item xs={12} sm={3} md={3}>
                            {isTW ?
                                <Card className='google-map-code' elevation={8}>
                                    <iframe
                                        title="ideal_location"
                                        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d903.6628694467491!2d121.51526902919255!3d25.04589909899792!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a972e623be93%3A0x3d166dd5439bbb5d!2z5Lqe5rSy5buj5aC05aSn5qiT566h55CG5aeU5ZOh5pyD!5e0!3m2!1sid!2stw!4v1651300750650!5m2!1sid!2stw'
                                        width='100%'
                                        height='280'
                                        frameBorder='0'
                                        style={{ border: 2 }}
                                        allowFullScreen=''
                                        aria-hidden='false'
                                        tabIndex='0' />
                                </Card>
                                :
                                <Card className='google-map-code' elevation={8}>
                                    <iframe
                                        title="ideal_location"
                                        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.9476291783326!2d114.18426741495432!3d22.279973585334165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340400543d035981%3A0x6fcdfd2c003afd74!2sCauseway%20Bay%20Commercial%20Building%2C%201-5%20Sugar%20St%2C%20Causeway%20Bay%2C%20Hong%20Kong!5e0!3m2!1sen!2sid!4v1591856797597!5m2!1sen!2sid'
                                        width='100%'
                                        height='280'
                                        frameBorder='0'
                                        style={{ border: 2 }}
                                        allowFullScreen=''
                                        aria-hidden='false'
                                        tabIndex='0' />
                                </Card>
                            }
                        </Grid>
                        {/* 2 - kantor */}
                        <Grid item xs={12} sm={5} md={5}>
                            <div id="ideal-location" className="pb-3">
                                <Typography variant="h4" className="mb-4 main-text" style={{ fontWeight: '500' }}>Kantor Kami</Typography>
                                <Grid container className="main-text" wrap="nowrap">
                                    <Grid item>
                                        <i className="fa fa-map-marker mt-1 mr-2" aria-hidden="true"></i>
                                    </Grid>
                                    <Grid item>
                                        {isTW ?
                                            <Link underline="always" href="https://www.google.com/maps/place/No.+9,+Section+2,+Zhongxiao+W+Rd,+Datong+District,+Taipei+City,+Republik+Tiongkok+103/@25.0486238,121.5076303,17z/data=!3m1!4b1!4m8!1m2!2m1!1s21F-6,+No.50,+Sec.+1,+ZhongXiao+West+Rd.,+ZhongZheng+Dist.,+Taipei+City+100!3m4!1s0x3442a90deadba73b:0xc0ea12faf6952072!8m2!3d25.048619!4d121.509819" target="_blank" title="Lokasi Kantor Kami" className="main-text" style={{ textDecoration: 'none' }}>
                                                <Typography variant="body2">iDeal Home Online</Typography>
                                                <Typography variant="body2">
                                                    <span style={ window.innerWidth < 500 ? {fontSize: '0.855rem'} : {}}>21F-6, NO.50, SEC. 1, ZHONGXIAO WEST RD.</span>
                                                </Typography>
                                                {/* <Typography variant="body2"></Typography> */}
                                                <div className="MuiTypography-body2" style={ window.innerWidth < 500 ? {fontSize: '0.855rem' } : {}}>
                                                    <div style={{ display: 'inline'}}>ZHONGZHENG DIST., TAIPEI CITY 100</div>
                                                    <div>(MRT TMS Exit M6 <span style={{ display: 'inline', ...styles.mrtTw }}/>)</div>
                                                </div>
                                                {/* {
                                                    window.outerWidth < 500 &&
                                                    <div className="MuiTypography-body2" style={{fontSize: '0.855rem' }}>
                                                        <div style={{ display: 'inline'}}>ZHONGZHENG DIST., TAIPEI CITY 100</div>
                                                        <div>(MRT TMS Exit M6 <span style={{ display: 'inline', ...styles.mrtTw }}/>)</div>
                                                    </div>
                                                }
                                                {
                                                    window.outerWidth > 700 &&
                                                    <div className="MuiTypography-body2">
                                                        <div style={{ display: 'inline' }}>ZHONGZHENG DIST., TAIPEI CITY 100 (MRT TMS Exit M6</div>
                                                        <div style={{ display: 'inline', ...styles.mrtTw }}></div>
                                                        <div style={{ display: 'inline' }}> )</div>
                                                    </div>
                                                } */}
                                            </Link>
                                            :
                                            <Link underline="always" href="https://www.google.com/maps/place/Causeway+Bay+Commercial+Building,+1-5+Sugar+St,+Causeway+Bay,+Hong+Kong/@22.280134,114.18648,17z/data=!4m5!3m4!1s0x340400543d035981:0x6fcdfd2c003afd74!8m2!3d22.2799736!4d114.1864561?hl=en" target="_blank" title="Lokasi Kantor Kami" className="main-text" style={{ textDecoration: 'none' }}>
                                                <Typography variant="body2">iDeal Home Online</Typography>
                                                <Typography variant="body2">
                                                    <span style={ window.innerWidth < 500 ? {fontSize: '0.855rem'} : {}}>RM 1508 15/F CAUSEWAY BAY COMMERCIAL BLDG</span>
                                                </Typography>
                                                {/* <Typography variant="body2"></Typography> */}
                                                <div className="MuiTypography-body2" style={ window.innerWidth < 500 ? {fontSize: '0.855rem' } : {}}>
                                                    <div style={{ display: 'inline' }}>3 SUGAR ST., CAUSEWAY BAY</div>
                                                    <div>(MTR CWB Exit E <span style={{ display: 'inline', ...styles.mrt }}/>)</div>
                                                </div>
                                                {/* {
                                                    window.outerWidth < 500 &&
                                                    <div className="MuiTypography-body2">
                                                        <div style={{ display: 'inline' }}>3 SUGAR ST., CAUSEWAY BAY</div>
                                                        <div>(MTR CWB Exit E <span style={{ display: 'inline', ...styles.mrt }}/>)</div>
                                                    </div>
                                                } */}
                                                {/* {
                                                    window.outerWidth > 700 &&
                                                    <div className="MuiTypography-body2">
                                                        <div style={{ display: 'inline' }}>3 SUGAR ST., CAUSEWAY BAY (MTR CWB Exit E </div>
                                                        <div style={{ display: 'inline', ...styles.mrt }}></div>
                                                        <div style={{ display: 'inline' }}> )</div>
                                                    </div>
                                                } */}
                                            </Link>
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                            {/*  */}
                            {isTW &&
                                <div>
                                    <Typography gutterBottom className="main-text mt-3 pb-2"><b>Customer Care Hotline:</b></Typography>
                                    <Link href="tel:+886223113000" className=" d-block" title="Hotline iDeal" style={{ borderRadius: '20px' }}>
                                        <div style={styles.hotLineTw} alt="HotLine iDeal" width="100%"></div>
                                    </Link>
                                </div>
                            }
                            {!isTW &&
                                <div>
                                    <Typography gutterBottom className="main-text mt-3 pb-2"><b>Customer Care Hotline:</b></Typography>
                                    <Link href="tel:+85229935000" className=" d-block" title="Hotline iDeal" style={{ borderRadius: '20px' }}>
                                        <div style={styles.hotLineHk} alt="HotLine iDeal" width="100%"></div>
                                    </Link>
                                </div>
                            }


                        </Grid>
                        {/* 3 - download now */}
                        <Grid item xs={12} sm={4} md={4}>
                            <Grid container direction="column">
                                {isTW?
                                <Typography id="ideal-tag" variant="h4" className="main-text" style={{ fontWeight: '500', minHeight: this.state.height }}>Aplikasi Belanja Indonesia Pertama di Taiwan</Typography>
                                :
                                <Typography id="ideal-tag" variant="h4" className="main-text" style={{ fontWeight: '500', minHeight: this.state.height }}>Aplikasi Belanja Indonesia Pertama di Hong Kong</Typography>
                                }
                                <Typography className="mt-3 pb-4 main-text"><b>Download iDeal Sekarang</b></Typography>
                                <Grid item xs={6} sm={12} md={6}>
                                    <PlayStore userLocation={this.props.userLocation || {}} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider className="my-3" />
                <Typography variant="caption" align="center" display="block" style={{ padding: '0 20px' }}>&copy; {new Date().getFullYear()} iDeal Home Online - iDeal Futura International Limited. All Rights Reserved.</Typography>
            </footer>
        );
    }
}

export default HomeFooter;