import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

// responsive in carousel
const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
        slidesToSlide: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};
// styles
const styles= {
    section: {
        paddingTop: "10vh",
        minHeight: '600px'
    },
    title: {
        color: "black",
        fontWeight: '500'
    },
    divider: {
        minWidth: "100px",
        width: "8vw",
        height: "5px",
    },
    carousel: {
        paddingBottom: "5vh",
    },
    card: {
        minWidth: "300px",
        maxWidth: "400px",
        minHeight: "300px",
        maxHeight: "400px",
        backgroundColor: "#FB6340",
        borderRadius: "15px"
    },
}

class Testimoni extends Component {
    // view
    render() {
        // set img from props
        let content = [];
        if (this.props.content) {
           this.props.content.forEach(value => {
                if (value.name === "testimoni") {
                    content = value.imageslink || [];
                }
           });
        }
        
        return (
            <section id="testimonial" style={styles.section}>
                {content.length>0 && (
                    <React.Fragment>
                        <Grid  container direction="column" alignItems="center">
                            <Typography variant="h3" color="inherit" align="center" style={styles.title}>Testimoni Pelanggan Kami</Typography>
                            <Divider className="mt-4 primary" style={styles.divider}/>
                        </Grid>
                        {/* carousel */}
                        <Grid container justify="center" style={styles.carousel}>
                           <Grid item xs={12} sm={12} md={9}>
                                <Carousel
                                    responsive={responsive}
                                    autoPlay
                                    autoPlaySpeed={10000}
                                    infinite={true}
                                    showDots={false}
                                    itemClass="px-2 py-5"
                                >
                                    {content.map((value, index) => {
                                        return (
                                            <Card elevation={3} style={styles.card} key={index}>
                                                <LazyLoadImage
                                                    alt={`Testimoni ${index}`}
                                                    effect="blur"
                                                    src={value} // use normal <img> attributes as props
                                                    width={"100%"} />
                                            </Card>
                                        )
                                    })}
                                </Carousel>
                           </Grid>
                        </Grid>
                    </React.Fragment>
                )}
            </section>
        );
    }
}

export default Testimoni;