const environments = {
  hk: {
    development: {
      // apiUrl: "https://api.i-dealhomeonline.com/api",
      // adminUrl: "https://api.i-dealhomeonline.com/admin"
      apiUrl: "https://apiprod-admin.i-dealhomeonline.com/api",
      adminUrl: "https://apiprod-admin.i-dealhomeonline.com/admin"
    },
    staging: {
      apiUrl: 'https://api-dev.i-dealhomeonline.com/api',
      adminUrl: "https://api-dev.i-dealhomeonline.com/admin",
    },
    production: {
      apiUrl: 'https://apiprod-admin.i-dealhomeonline.com/api',
      adminUrl: "https://apiprod-admin.i-dealhomeonline.com/admin",
    },
  },
  tw: {
    development: {
      // apiUrl: "https://apidev-tw.i-dealhomeonline.com/api",
      // adminUrl: "https://apidev-tw.i-dealhomeonline.com/admin"
      apiUrl: "https://apiprod-tw.i-dealhomeonline.com/api",
      adminUrl: "https://apiprod-tw.i-dealhomeonline.com/admin"
    },
    staging: {
      apiUrl: "https://apidev-tw.i-dealhomeonline.com/api",
      adminUrl: "https://apidev-tw.i-dealhomeonline.com/admin"
    },
    production: {
      apiUrl: "https://apiprod-tw.i-dealhomeonline.com/api",
      adminUrl: "https://apiprod-tw.i-dealhomeonline.com/admin"
    }
  }
}

module.exports = fetch('https://geolocation-db.com/json/')
  .then(response => {
    console.log("ini response: ", response)
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    return response.json()
  }).catch(err => {
    console.log("this error",err)
  })
  .then(function (response) {
    const hostname = window && window.location && window.location.hostname
    let region = "hk"
    let env = 'staging'

    if (response && response.country_code === "TW") {
      region = "tw"
    } else region = "hk"
    if (
      hostname &&
      (hostname.includes('staging.i-dealhomeonline.com') ||
        hostname.includes('ideal-dev.netlify.com'))
    ) {
      env = 'staging'
    } else if (
      hostname &&
      (hostname.includes('i-dealhomeonline.com') ||
        hostname.includes('ideal.netlify.com'))
    ) {
      env = 'production'
    } else if (hostname && hostname.includes('localhost')) {
      env = 'development'
    } else {
      env = 'development'
    }
    return environments[region.toLocaleLowerCase()][env.toLowerCase()]
  })
