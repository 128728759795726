import React, { Component } from 'react';

import playStore from '../../assets/img/theme/google-play-badge-id-2.gif';

import {adminUrl} from '../../config/config';

class PlayStore extends Component {
    // onclick
    onGooglePlayButtonClick = () => {
        fetch(`${adminUrl}/visitplaystore`, {
            method: 'POST',
            headers: {
            Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                city: this.props.userLocation? this.props.userLocation.city: '',
                region: this.props.userLocation? this.props.userLocation.region: '',
                country: this.props.userLocation? this.props.userLocation.country: '',
                status: 'download_apk',
            }),
        }).then(function () {
            // window.location.href = 'https://ideal-homeonline-production.s3-ap-southeast-1.amazonaws.com/iDeal.apk';
            window.location.href = 'https://play.google.com/store/apps/details?id=com.dihardjasoftware.ideal&hl=en';
            // window.open('https://play.google.com/store/apps/details?id=com.dihardjasoftware.ideal&hl=en', '_blank');
        });
        
    };
    render() {
        return (
            <div onClick={this.onGooglePlayButtonClick} className="cursor-pointer" title="Download iDeal Sekarang" >
                <img alt="download_now" width="100%" src={playStore} style={{borderRadius: 5}}></img>
            </div>
        );
    }
}

export default PlayStore;