import React from 'react';

// material
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';

// core components
import HomeNavBar from 'components/Navbars/HomeNavBar.js';
import Intro from './Intro/Intro.js';
import Ideal from './Ideal/Ideal.js';
import Testimoni from './Testimoni/Testimoni.js';
import Facebook from './Facebook/Facebook.js';
import FabCustom from './FabCustom/FabCustom.js';
import HomeFooter from 'components/Footers/HomeFooter.js';

import Axios from 'helper/Axios.js';

// import { adminUrl } from "../../config/config.js";

// material theme
const primary = "#fb6340";
let adminUrl = null;
let apiUrl = null;
let isTW = false

let theme = createMuiTheme({
  palette: {
    primary: {
      main: primary,
      contrastText: "#fff"
    },
    secondary: {
      main: "#fff",
      contrastText: primary
    }
  },
  typography: {
    fontFamily: [
      'Poppins',
      'Arial',
    ].join(','),
  },
});

theme = responsiveFontSizes(theme);
class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userLocation: {
        city: '',
        region: '',
        country: '',
      },
      sectionList: [],
      bannerData: [],
      isUseApk: false,
    };
    this.refHome = React.createRef();
    this.refService = React.createRef();
    this.refTestimoni = React.createRef();
    this.refLocation = React.createRef();
  }

  componentDidMount = async () => {
    let promiseConfig = await require("../../config/config.js").then(function (e) {
      console.log(e)
      return e
    });
    adminUrl = promiseConfig.adminUrl
    apiUrl = promiseConfig.apiUrl

    if (this.props.location.pathname !== '/') {
      console.log(this.props.location)
      this.scrollTo(this.props.location.pathname);
    } else {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
    let visitLocation = await fetch('https://geolocation-db.com/json/').then(response => response.json()).then(function (response) {
      return response;
    })
    if (visitLocation) {
      this.setLocation(visitLocation.country_name, "country")
      this.setLocation(visitLocation.state, "region")
      this.setLocation(visitLocation.city, "city")
    }
    await fetch(`${adminUrl}/webvisit`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        city: this.state.userLocation ? this.state.userLocation.city : '',
        region: this.state.userLocation ? this.state.userLocation.region : '',
        country: this.state.userLocation ? this.state.userLocation.country : '',
      }),
    }).then(function (response) {
      console.log(response.status);
    });

    this.getHomePage();
  }

  componentDidUpdate = () => {
    if (this.props.location.pathname !== '/') {
      console.log(this.props.location)
      this.scrollTo(this.props.location.pathname);
    } else {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }
  getHomePage = async () => {
    if ((adminUrl === "https://apiprod-tw.i-dealhomeonline.com/admin") || (adminUrl === "https://apidev-tw.i-dealhomeonline.com/admin")) {
      isTW = true
    }
    console.log("istw: ", isTW)
    try {
      let { data } = await Axios.Get({
        url: 'https://apiprod-admin.i-dealhomeonline.com/admin/gethomepagecontent'
      });
      if (isTW === true || (data && data[0] && data[0].homePage)) {
        let banner = await Axios.Get({
          url: `${apiUrl}/web/banner`
        })
        let bannerData2 = [];
        bannerData2 = banner.data
        this.setState({
          sectionList: data[0].homePage.sectionList,
          isUseApk: data[0].homePage.isUseApk,
          bannerData: bannerData2
        })
      }
    } catch (error) {
      console.log("error here : ", error)
    }
  }

  setLocation = (value, name) => {
    //  if (value.error) {
    this.setState((state) => ({
      userLocation: {
        ...state.userLocation,
        [name]: value,
      }
    }));
    //  }
  }

  // get link from navbar action click
  linkCallback = (link) => {
    this.scrollTo(link);
  }
  scrollTo = (link) => {
    const pathname = link.replace('/', '');
    let offsetHeight = 0;
    if (pathname === 'home') {
      offsetHeight = this.refHome.current.offsetTop;
    } else if (pathname === 'service') {
      offsetHeight = this.refService.current.offsetTop;
    } else if (pathname === 'testimonial') {
      offsetHeight = this.refTestimoni.current.offsetTop;
    } else if (pathname === 'location') {
      offsetHeight = this.refLocation.current.offsetTop;
    } else {
      offsetHeight = 0;
    }
    window.scrollTo(0, offsetHeight);
    console.log('oofff sscroll', document.scrollingElement.scrollTop)
    console.log('oofff home1', this.refHome.current.offsetTop)
    console.log('oofff service1', this.refService.current.offsetTop)
    console.log('oofff testimonial1', this.refTestimoni.current.offsetTop)
    console.log('oofff location1', this.refLocation.current.offsetTop)
  }

  render() {
    return (
      <>
        <main ref='main'>
          <ThemeProvider theme={theme}>
            {/* header */}
            <div ref={this.refHome}>
              <HomeNavBar linkCallback={this.linkCallback} />
            </div>
            {/* intro section */}
            <Intro userLocation={this.state.userLocation} content={this.state.sectionList} isUseApk={this.state.isUseApk} bannerData={this.state.bannerData} />
            {/* ideal value */}
            <div ref={this.refService}>
              <Ideal />
            </div>
            {/* testimoni */}
            <div ref={this.refTestimoni}>
              <Testimoni content={this.state.sectionList} />
            </div>
            {/* facebook */}
            <Facebook userLocation={this.state.userLocation} />
            {/* fab */}
            <FabCustom />
            {/* footer */}
            <div ref={this.refLocation}>
              <HomeFooter userLocation={this.state.userLocation} />
            </div>
          </ThemeProvider>
        </main>
      </>
    );
  }
}

export default Landing;
