import axios from 'axios'

const queryParser = params => {
  let queryParams = ''
  for (let key in params) {
    if (!queryParams) {
      queryParams = queryParams.concat(`?${key}=${params[key]}`)
    } else {
      queryParams = queryParams.concat(`&${key}=${params[key]}`)
    }
  }
  return queryParams
}

const Axios = {
  Get: ({ url, params, responseType }) => {
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: url + queryParser(params),
          method: 'GET',
          ...(responseType ? { responseType } : {})
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  Post: ({ url, params, data }) => {
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: url,
          method: 'POST',
          data: data
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  Put: ({ url, params, data }) => {
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: url + queryParser(params),
          method: 'PUT',
          data
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  Patch: ({ url, params, data }) => {
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: url + queryParser(params),
          method: 'PATCH',
          data
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  Delete: ({ url, params, data }) => {
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: url + queryParser(params),
          method: 'DELETE',
        })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export default Axios
