import React from 'react';
import LandingPage from './home/Landing.js';

class Index extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    if (this.props.location.search) {
      this.props.history.replace('/')
    }
  }
  render() {
    return (
      <>
        <main ref='main'>
          <LandingPage location={this.props.location} history={this.props.history} />
        </main>
      </>
    );
  }
}

export default Index;
